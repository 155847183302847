<template>
  <div>

    <div class="card">
      <div class="card-body">

        <div class="position-relative wd-50">
          <input v-on:keyup.enter="loadCompanies(1)" type="text" class="form-control ps-5" v-model="filters.search" placeholder="Search Company..."> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
        </div>

        <br/>

        <button type="button" class="btn btn-sm btn-light ms-2" style="float: right;" title="Manage Columns" data-bs-toggle="modal" data-bs-target="#manageColumnsModal">
          <i class="bx bxs-grid me-0"></i>
        </button>
        <button type="button" style="float: right"  data-bs-toggle="modal" data-bs-target="#createCompanyModal" class="btn btn-sm btn-primary px-5">New Company</button>
        <pagination @search="loadCompanies" :data="pagination"></pagination>

        <table id="example" class="table table-hover" style="width:100%">
          <thead>
          <tr>
            <th v-show="columns.name">Name</th>
            <th v-show="columns.street">Street</th>
            <th v-show="columns.town">Town</th>
            <th v-show="columns.county">County</th>
            <th v-show="columns.postcode">Postcode</th>
            <th v-show="columns.country">Country</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="company in companies" :key="company.id" style="cursor: pointer;" @click="$router.push({name: 'admin.companies.view', params: {id: company.id}})">
            <td v-show="columns.name">{{company.name}}</td>
            <td v-show="columns.street">{{company.street}}</td>
            <td v-show="columns.town">{{company.town}}</td>
            <td v-show="columns.county">{{company.county}}</td>
            <td v-show="columns.postcode">{{company.postcode}}</td>
            <td v-show="columns.country">{{company.country}}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th v-show="columns.name">Name</th>
            <th v-show="columns.street">Street</th>
            <th v-show="columns.town">Town</th>
            <th v-show="columns.county">County</th>
            <th v-show="columns.postcode">Postcode</th>
            <th v-show="columns.country">Country</th>
          </tr>
          </tfoot>
        </table>

        <pagination @search="loadCompanies" :data="pagination"></pagination>
      </div>
    </div>

    <!-- MODALS -->

    <div class="modal fade" id="createCompanyModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Company</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <p>Fields marked <span class="text-danger">*</span> are required</p>
              </div>
              <div class="col-md-12 mb-2">
                <label for="companyName" class="form-label">Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="companyName" v-model="company.name">
              </div>
              <div class="col-md-12 mb-2">
                <label for="companyStreet" class="form-label">Street</label>
                <input type="text" class="form-control" id="companyStreet" v-model="company.street">
              </div>
              <div class="col-md-12 mb-2">
                <label for="companyTown" class="form-label">Town</label>
                <input type="text" class="form-control" id="companyTown" v-model="company.town">
              </div>
              <div class="col-md-12 mb-2">
                <label for="companyCounty" class="form-label">County</label>
                <input type="text" class="form-control" id="companyCounty" v-model="company.county">
              </div>
              <div class="col-md-12 mb-2">
                <label for="companyPostcode" class="form-label">Postcode</label>
                <input type="text" class="form-control" id="companyPostcode" v-model="company.postcode">
              </div>
              <div class="col-md-12 mb-2">
                <label for="companyCountry" class="form-label">Country</label>
                <input type="tel" class="form-control" id="companyCountry" v-model="company.country">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeCreateCompanyModal'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="createCompany" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="manageColumnsModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Manage Columns</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Please note these settings will be saved locally in your browser. If you clear your browser settings these settings may reset.</p>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="nameColumn" @change="updateColumnSettings" v-model="columns.name">
              <label class="form-check-label" for="nameColumn">Name</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="streetColumn" @change="updateColumnSettings" v-model="columns.street">
              <label class="form-check-label" for="streetColumn">Street</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="townColumn" @change="updateColumnSettings" v-model="columns.town">
              <label class="form-check-label" for="townColumn">Town</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="countyColumn" @change="updateColumnSettings" v-model="columns.county">
              <label class="form-check-label" for="countyColumn">County</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="postcodeColumn" @change="updateColumnSettings" v-model="columns.postcode">
              <label class="form-check-label" for="postcodeColumn">Postcode</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="countryColumn" @change="updateColumnSettings" v-model="columns.country">
              <label class="form-check-label" for="countryColumn">Country</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeManageColumnsModal'>Close</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* global $ */
import axios from 'axios';
import Pagination from '../../../components/Pagination.vue';
import SubmitButton from '../../../components/SubmitButton.vue';
export default {
  components: { Pagination, SubmitButton },
  name: 'admin.companies',
  data(){
    return {
      companies: [],
      pagination: {
        lastPage: 0,
        page: 0,
        showing: 0,
        total: 0
      },
      company: {
        name: '',
        street: null,
        town: null,
        county: null,
        postcode: null,
        country: null
      },
      creating: false,
      filters: {
        search: ""
      },
      columns: {
        name: true,
        street: true,
        town: true,
        county: true,
        postcode: true,
        country: true
      }
    }
  },
  mounted(){
    this.loadCompanies();
    this.loadColumnSettings();
  },
  methods: {
    loadCompanies(page = 1){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/company?page=${page}&search=${this.filters.search}`)
          .then(response => {
            this.companies = response.data.companies;
            this.pagination = response.data.pagination;
          })
          .catch(error => {
            this.$error('Failed to load companies.', error);
          });
    },
    createCompany(){
      this.creating = true;
      axios.put(`${process.env.VUE_APP_API_URL}/v1/company`, this.company)
          .then(() => {
            $('#closeCreateCompanyModal').click();
            this.$success("Created new company");
            this.creating = false;
            this.company = {
              name: '',
              street: null,
              town: null,
              county: null,
              postcode: null,
              country: null
            }
            this.loadCompanies(this.pagination.page);
          })
          .catch(error => {
            this.$error("Failed to create new company.", error);
            this.creating = false;
          })
    },
    updateColumnSettings(){
      localStorage.setItem('link::companies::columns', JSON.stringify(this.columns));
    },
    loadColumnSettings(){
      let savedColumns = localStorage.getItem('link::companies::columns');
      console.log(savedColumns);
      if(savedColumns !== undefined && savedColumns !== null && savedColumns !== 'null' && savedColumns !== ''){
        console.log('Updating columns');
        this.columns = JSON.parse(savedColumns);
      }
    },

  }
}
</script>